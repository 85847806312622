import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import { BsFillCheckCircleFill } from "react-icons/bs";

const TNPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "About-Us-hero-image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			img1: wpMediaItem(title: { eq: "Trina" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Catriona Neville",
				item: {
					url: `${siteUrl}/about-us/catriona-neville`,
					id: `${siteUrl}/about-us/catriona-neville`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Catriona Neville | The Facial Rehabilitation Centre"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/catriona-neville`,
					title: "Catriona Neville | The Facial Rehabilitation Centre",
					description: "",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						subTitle="Home > About us > Catriona Neville"
						title="Catriona Neville"
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
				</section>
				<section className="position-relative py-5 pt-md-0 pb-lg-7">
					<Container>
						<Row>
							<Col
								style={{ top: "-12rem" }}
								className="position-absolute d-none d-xl-block "
								lg={3}
							>
								<div>
									<GatsbyImage
										style={{
											maxWidth: "100%",
										}}
										image={data.img1.localFile.childImageSharp.gatsbyImageData}
										alt={data.img1.altText}
									/>
								</div>
							</Col>
						</Row>
						<Row className="  ps-xl-7 justify-content-xl-end">
							<Col className=" d-xl-none pb-5 pb-lg-0 " lg={4}>
								<div>
									<GatsbyImage
										style={{
											maxWidth: "100%",
										}}
										image={data.img1.localFile.childImageSharp.gatsbyImageData}
										alt={data.img1.altText}
									/>
								</div>
							</Col>
							<Col lg={7} xl={8}>
								<p>
									Catriona Neville is an extended scope practitioner
									physiotherapist with over 20 years clinical experience. She
									has specialised in the assessment and treatment of facial
									paralysis for over 14 years. Catriona’s treatment strategies
									involve an individualised evidence-based approach, including
									patient education, relaxation, neuromuscular re-education,
									soft tissue work and biofeedback, with the holistic goal of
									improving each patient’s quality of life and achieving their
									functional and psychosocial goals.
								</p>
								<p>
									Catriona has a first-class degree in physiotherapy and
									distinction in postgraduate clinical research. She is an
									independent prescriber and injector. She works alongside a
									team of plastics and ophthalmic consultants, physiotherapists,
									occupational therapists, speech therapists and psychological
									therapists in the facial palsy specialist service at Queen
									Victoria Hospital running therapy clinics and chemodenervation
									(botulinum toxin) clinics.
								</p>
								<p>
									Catriona is a founding member and Chair of Facial Therapy
									Specialists International with whom she teaches on both acute
									and advanced facial palsy courses. She is a member of the
									medical advisory board of facial palsy UK and runs support
									groups for FPUK. She has been an invited speaker at UK and
									international facial conferences including the Royal Society
									of Medicine and the International Facial Nerve Symposiums.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section className=" pb-5 pb-lg-7 pb-xl-7 pt-xl-9">
					<Container>
						<Row>
							<Col className="text-center">
								<h2 className="text-dark-bg pb-4 ">Experience</h2>
							</Col>
						</Row>
						<Row className="pt-5">
							<Col className="pb-5 pb-lg-0" lg={4}>
								<BsFillCheckCircleFill className="text-secondary fs-2 mb-3" />
								<p className=" ubuntu-bold fs-5">
									Extended Scope Practitioner Physiotherapist
								</p>
							</Col>
							<Col className="pb-5 pb-lg-0" lg={4}>
								<BsFillCheckCircleFill className="text-secondary fs-2 mb-3" />
								<p className=" ubuntu-bold fs-5">
									Specialising in Facial Therapy & Facial Injecting (BTX-A)
								</p>
							</Col>
							<Col lg={4}>
								<BsFillCheckCircleFill className="text-secondary fs-2 mb-3" />
								<p className=" ubuntu-bold fs-5">
									Independent Prescriber and Botulinum Toxin (BTX-A)
									practitioner
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="mb-5 mb-lg-0" lg={6}>
								<h2 className="text-dark-bg pb-4 ">Qualifications</h2>
								<p>
									· BSc (Hons) 1st Class Physiotherapy, University of
									Northumbria, 2001
								</p>
								<p>· PG Cert (Distinction) Clinical Research, 2013</p>
								<p>· Non-Medical Prescribing, University of Surrey, 2021</p>
								<p>
									· 12-month competency-based training programme Botulinum Toxin
									Type A, Queen Victoria Hospital NHS Foundation Trust, 2012
								</p>
							</Col>
							<Col lg={6}>
								<h2 className="text-dark-bg pb-4 ">Professional Memberships</h2>
								<p>
									. Health Care Professions Council - PH59997; additional
									entitlements: Independent Prescribing & Supplementary
									Prescribing
								</p>
								<p>· Chartered Society of Physiotherapy - 064897</p>
								<p>· Facial Therapy Specialists International - Chair</p>
								<p>· Advanced Practice Physiotherapy Network</p>
								<p>
									· ACPIVR Association of Chartered Physiotherapists in
									Vestibular Rehabilitation
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="text-dark-bg pb-5 text-center ">
									Credentials & Employment History
								</h2>
								<p>
									. National and International lecturer & course leader in
									facial rehabilitation
								</p>
								<p>
									· Founding member and Chair, Facial Therapy Specialists
									International
								</p>
								<p>
									· Invited member of medical advisory board, Facial Palsy UK
								</p>
								<p>· Independent non-medical prescriber since 2016</p>
								<p>
									· Independent Practitioner in Botulinum Toxin Clinics, Queen
									Victoria Hospital, since 2012
								</p>
								<p>
									· Lead Clinician Band 8a Physiotherapist, Facial Palsy Therapy
									Team, Queen Victoria Hospital NHS Foundation Trust since 2007
								</p>
								<p>
									· Member of Queen Victoria Hospital Specialist Facial Palsy
									Multidisciplinary Team, National Facial Palsy Centre of
									Excellence since 2007
								</p>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default TNPage;
